/* Color Pallete */
/* Color Role Definitions */
/* Font Size Definitions */
/*0.9rem;*/
.answerPage {
  opacity: 0;
  transition: all 200ms 600ms ease-in;
}
.answerPage layer-link-message-view .video-description {
  margin: 0;
  color: #ffffff;
  padding: 10px 30px 0 10px;
  line-height: 19px;
  font-size: 16px;
  font-weight: normal;
}
.answerPage layer-conversation-view layer-message-item-received .layer-sender-name {
  font-weight: 500;
  font-size: 0.75rem;
}
.answerPage layer-conversation-view layer-message-item-received .bot {
  color: #FFC33B;
}
.answerPage .layer-compose-bar__border-top {
  border-top: 1px solid #f0f1f3;
}
.answerPage .show-more {
  display: flex !important;
  justify-content: flex-end;
  z-index: 10;
  opacity: 1;
}
.answerPage .show-more button {
  cursor: pointer;
  position: fixed;
  bottom: 60px;
  right: calc(50% - 21px);
  padding: 0;
  margin: 0;
  z-index: 1;
}
.answerPage .show-more.xircles-hide {
  opacity: 0;
}
.answerPage .disable-compose-bar {
  opacity: 0.5;
}
.answerPage .disable-compose-bar layer-send-button,
.answerPage .disable-compose-bar button,
.answerPage .disable-compose-bar textarea {
  cursor: not-allowed;
  pointer-events: none;
}
.answerPage .show-more-button {
  width: 36px;
  height: 30px;
  border-top-left-radius: 100px;
  border-top-right-radius: 100px;
  background-color: #f0f1f3;
}
.answerPage .show-more-button i {
  color: #494d5b;
}
.answerPage.open {
  opacity: 1;
}
.answerPage.open .back-button,
.answerPage.open .xircles-status {
  transition: all 200ms 230ms ease-in-out;
  opacity: 1;
}
.answerPage.open .panel-header button i {
  opacity: 1;
  transform: rotate(0);
}
.answerPage.open .panel-header > div {
  max-width: 660px;
  margin: auto;
  position: relative;
}
.answerPage.open .question-panel layer-conversation-view {
  opacity: 1;
}
.answerPage.open .question-panel .panel-header {
  box-shadow: 0 4px 4px -1px rgba(0, 0, 0, 0.19);
}
.answerPage .question-panel {
  color: #494d5b;
  background-color: rgba(0, 0, 0, 0);
}
.answerPage .question-panel layer-message-list {
  margin-top: 100px;
  overflow-x: hidden;
}
.answerPage .question-panel layer-conversation-view {
  height: 100%;
  background: rgba(0, 0, 0, 0);
  opacity: 0;
  transition: all 200ms 230ms ease-in;
}
.answerPage .question-panel .operator + layer-conversation-view {
  height: calc(100% - 35px);
}
.answerPage .question-panel .panel-header {
  z-index: 20;
  position: absolute;
  /*fixed;*/
  width: 100%;
  min-height: 94px;
  background: white;
  transition: all 200ms 230ms ease-in;
  box-shadow: 0 4px 4px -1px rgba(0, 0, 0, 0);
  display: block;
}
.answerPage .question-panel .panel-header .title {
  font-size: 24px;
  color: #494d5b;
  font-weight: bold;
  margin: 12px 28px 12px 12px;
}
.answerPage .panel-header button {
  border: none;
  padding: 0;
  line-height: 0.7;
  margin-top: 2px;
  cursor: pointer;
}
.answerPage .panel-header button i {
  background: rgba(0, 0, 0, 0);
  color: #009B77;
  transition: all 200ms 230ms ease-in-out;
  opacity: 0;
  transform: rotate(-90deg);
}
.answerPage .back-button {
  width: 30px;
  opacity: 0;
}
.answerPage .xircles-operator-status {
  display: none;
}
.answerPage .header-operator .operator-status__wrapper {
  position: absolute;
  top: 2px;
  right: 20px;
  display: flex;
}
.answerPage .header-operator .operator-status__wrapper div:not(:last-of-type) {
  margin-right: 23px;
}
.answerPage .header-operator .operator-status__wrapper .xircles-status {
  position: relative;
  float: unset;
  right: unset;
  top: unset;
}
.answerPage .header-operator .operator-status__wrapper .xircles-operator-status {
  display: block;
  width: fit-content;
  padding: 3px 10px;
  background: #aaacb2;
  border-radius: 12px;
  line-height: 14px;
  font-family: Roboto, sans-serif;
  font-size: 12px;
  color: white;
}
.answerPage .xircles-status {
  float: right;
  position: absolute;
  right: 20px;
  top: 12px;
  opacity: 0;
}
.answerPage .layer-message-item-layer-carousel-message-view .layer-message-item-main {
  justify-content: flex-start;
  padding-left: 30px;
}
@media only screen and (max-width: 600px) {
  .answerPage .layer-message-item-layer-carousel-message-view .layer-message-item-main {
    padding-left: 20px;
  }
}
@media only screen and (max-width: 460px) {
  .answerPage .layer-message-item-layer-carousel-message-view .layer-message-item-main {
    padding-left: 2px;
  }
}
.answerPage .layer-message-item-layer-carousel-message-view .layer-message-item-main__operator {
  display: flex;
  justify-content: flex-end;
  padding-right: 15px;
}
@media only screen and (max-width: 600px) {
  .answerPage .layer-message-item-layer-carousel-message-view .layer-message-item-main__operator {
    padding-right: 20px;
  }
}
@media only screen and (max-width: 460px) {
  .answerPage .layer-message-item-layer-carousel-message-view .layer-message-item-main__operator {
    padding-right: 2px;
  }
}
.answerPage .layer-carousel-message-view {
  overflow: unset;
  max-width: 660px !important;
}
.answerPage .layer-carousel-message-view layer-message-viewer:nth-child(1) {
  margin-left: 0;
}
.answerPage .layer-carousel-message-view layer-message-viewer:last-child {
  margin-right: 0;
}
.answerPage .layer-carousel-message-view .layer-carousel-message-view-items:after {
  display: none;
}
.answerPage .layer-message-type-view span:nth-child(1) {
  left: -44px;
}
.answerPage .layer-next-icon,
.answerPage .layer-previous-icon {
  width: 24px;
}
@media only screen and (max-width: 768px) {
  .answerPage .layer-next-icon,
  .answerPage .layer-previous-icon {
    display: none;
  }
}
.answerPage .layer-message-item-main {
  overflow: unset;
}
.answerPage .layer-next-icon {
  right: -44px;
}
.answerPage layer-send-button {
  color: #007C5F !important;
}
.answerPage .composer-button-panel__actions {
  display: flex;
  flex-direction: row;
}
.answerPage .composer-button-panel__actions button {
  margin: 0px;
  padding-left: 7px;
  padding-right: 7px;
}
.layer-compose-bar-transition {
  transition: padding 200ms !important;
}
@keyframes opacity-hiding {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

/* Color Pallete */
/* Color Role Definitions */
/* Font Size Definitions */
/*0.9rem;*/
.pull-down-item {
  border-bottom: 1px solid #f0f1f3;
}
.pull-down-item__suggestion {
  cursor: pointer;
  color: #494d5b;
  font-family: "Roboto", sans-serif;
  font-size: 18px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.17;
  letter-spacing: normal;
  display: flex;
  justify-content: space-between;
}
.pull-down-item__suggestion-text {
  align-self: center;
}
.pull-down-item__go-button {
  color: #c1c3c9;
  padding: 0 6px 0 7px;
}
.pull-down-item__go-button .icon-select-suggestion {
  cursor: pointer;
  font-size: 16px;
}

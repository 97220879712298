/* Color Pallete */
/* Color Role Definitions */
/* Font Size Definitions */
/*0.9rem;*/
.answerPage.open .panel-header button i {
  opacity: 1;
  transform: rotate(0);
}
.answerPage.open .question-panel .operator-button-bar {
  display: none;
}
.answerPage.open .question-panel .operator .operator-button-bar {
  display: flex;
  background: #494d5b;
  height: 35px;
  justify-content: center;
}
.answerPage.open .question-panel .operator .operator-button-bar__wrapper {
  width: 660px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.answerPage.open .question-panel .operator .operator-button-bar__wrapper button {
  cursor: pointer;
  padding: 5px 8px;
  background: #B2E1D6;
  border-radius: 3px;
  line-height: 15px;
  font-family: Roboto, sans-serif;
  font-size: 13px;
  font-weight: bold;
  color: #494d5b;
}
.answerPage.open .question-panel .operator .operator-button-bar__wrapper button:not(:last-of-type) {
  margin-right: 5px;
}
.answerPage.open .question-panel .operator .operator-button-bar__wrapper button:hover {
  background: #009B77;
}
.answerPage.open .question-panel .operator .operator-button-bar__wrapper .button-active {
  background: #009B77;
}
.answerPage.open .question-panel .xircles-confirm {
  width: 0px;
  height: 0px;
  border-radius: 15.5px;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.5);
  background-color: #ffffff;
  z-index: 100;
  position: absolute;
  top: 80px;
  right: 10px;
  opacity: 0;
  display: flex;
  overflow: hidden;
  transition: all 200ms ease-in-out;
}
.answerPage.open .question-panel .xircles-confirm.open {
  align-items: center;
  animation: appear 0.2s ease-in forwards;
}
.answerPage.open .question-panel .xircles-confirm.close {
  align-items: center;
  animation: disappear 0.2s ease-out forwards;
}
.answerPage.open .question-panel .xircles-confirm .xircles-confirm-text {
  display: inline-block;
  margin: 0 15px 0 11px;
}
.answerPage.open .question-panel .xircles-confirm .xircles-confirm-action {
  width: 24px;
  height: 24px;
  margin: 3px 5px 4px;
  display: inline-block;
  cursor: pointer;
}
.answerPage.open .question-panel .xircles-confirm .xircles-confirm-action.confirm-yes {
  color: #009B77;
}
.answerPage.open .question-panel .xircles-confirm .xircles-confirm-action.confirm-no {
  color: #ccced3;
}
.answerPage .panel-header button {
  border: none;
  padding: 0;
  line-height: 0.7;
  margin-top: 2px;
  cursor: pointer;
}
.answerPage .panel-header button i {
  background: rgba(0, 0, 0, 0);
  color: #009B77;
  transition: all 200ms ease-in-out;
  opacity: 0;
  transform: rotate(-90deg);
}
.answerPage .question-panel .background-menu {
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  background: #494d5b;
  z-index: 11;
  box-shadow: 0 4px 4px -1px rgba(0, 0, 0, 0.19);
  width: 100%;
  height: 100%;
  transition: all 200ms ease-in-out;
}
.answerPage .question-panel .background-menu i {
  display: block;
  margin-bottom: 10px;
}
.answerPage .question-panel .background-menu li {
  margin: 0;
  color: white;
  font-size: 12px;
  width: 64px;
  text-align: center;
  font-weight: lighter;
  cursor: pointer;
}
.answerPage .question-panel .background-menu ul {
  width: 100%;
  list-style: none;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-right: 12px;
}
.answerPage .question-panel .background-menu__operator {
  top: 35px;
}
.answerPage .question-panel .panel-header {
  z-index: 20;
  position: absolute;
  /*fixed;*/
  min-height: 94px;
  background: white;
  transition: all 200ms ease-in-out;
  display: block;
}
.answerPage .question-panel .panel-header .spike {
  position: absolute;
  top: 0;
  right: 0;
  width: 44px;
  height: 100%;
  cursor: pointer;
}
.answerPage .question-panel .panel-header .spike div {
  position: absolute;
  top: 50%;
  right: 20px;
  width: 5px;
  height: 40px;
  border-radius: 2.5px;
  background: #ccced3;
  transform: translateY(-50%);
}
.answerPage .question-panel .panel-header .spike:hover div {
  animation: handle 700ms ease-in-out;
}
.answerPage .question-panel .panel-header .title {
  font-size: 24px;
  font-weight: bold;
  margin: 12px 28px 12px 12px;
  max-height: 84px;
  overflow: hidden;
  transition: max-height 200ms ease-in-out;
  line-height: 28px;
}
.answerPage .question-panel .panel-header .title.opened i {
  transform: rotate(180deg);
}
.answerPage .question-panel .panel-header .title i {
  cursor: pointer;
  position: absolute;
  color: #009B77;
  bottom: 0;
  right: 0;
  transform: rotate(0deg);
  transition: all 200ms ease-in-out;
  z-index: 1;
}
.answerPage .question-panel .panel-header .title p {
  margin: 0;
}

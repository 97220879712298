/* Color Pallete */
/* Color Role Definitions */
/* Font Size Definitions */
/*0.9rem;*/
.long-copy-container {
  position: relative;
  z-index: 21;
  background-color: #ffffff;
  height: 100vh;
}
.long-copy-container header {
  box-shadow: 0 4px 4px -1px rgba(0, 0, 0, 0.19);
  padding: 14px;
}
.long-copy-container header h1 {
  margin: 0;
  font-size: 24px;
  line-height: 28px;
}
.long-copy-container header .back {
  color: #009B77;
  padding: 0;
  position: relative;
  left: -11px;
  bottom: 4px;
  margin-bottom: 9px;
  cursor: pointer;
}
.long-copy-container header > div {
  margin: 0 auto 0 auto;
  max-width: 660px;
  position: relative;
}
@keyframes opacity-animation {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes button-appearing {
  from {
    opacity: 0;
    bottom: -120px;
  }
  to {
    opacity: 1;
    bottom: 0;
  }
}
.long-copy-container main {
  padding: 14px;
  opacity: 0;
  animation: opacity-animation 600ms 600ms forwards;
}
.long-copy-container main .show {
  margin: 10px auto 0 auto;
  position: relative;
  bottom: -120px;
  display: block;
  color: #009B77;
  border-radius: 19.5px;
  border: 1px solid #009B77;
  font-size: 16px;
  line-height: 19px;
  padding: 10px 18px;
  animation: button-appearing 300ms 1200ms forwards;
}
.long-copy-container main .content {
  max-width: 660px;
  margin: 0 auto 0 auto;
  overflow: hidden;
  position: relative;
}
.long-copy-container main .content .img {
  width: 100%;
  background-color: #d8d8d8;
  border-radius: 10px;
}
.long-copy-container main .content .img-container-ratio-1-1,
.long-copy-container main .content .img-container-ratio-2-1,
.long-copy-container main .content .img-container-ratio-3-2 {
  margin: 10px 0;
}
.long-copy-container main .content .img-container-ratio-1-1 {
  max-width: 250px;
  display: inline-block;
  width: 100%;
  margin-right: 20px;
}
@media screen and (max-width: 375px) {
  .long-copy-container main .content .img-container-ratio-1-1 {
    margin-right: 0;
  }
}
.long-copy-container main .content .figure-header {
  font-size: 16px;
  line-height: 19px;
  font-weight: bold;
  margin: 30px 0 10px;
}
.long-copy-container main .content .figure-container {
  width: 250px;
  display: inline-block;
  margin-bottom: 10px;
  margin-right: 20px;
}
@media screen and (max-width: 375px) {
  .long-copy-container main .content .figure-container {
    margin-right: 0;
  }
}
.long-copy-container main .content .figure-title {
  margin: 0 0 10px 0;
}
.long-copy-container main .content .ratio-1-1 {
  padding-top: 104%;
}
.long-copy-container main .content .img-container-ratio-2-1 {
  max-width: 520px;
}
.long-copy-container main .content .ratio-2-1 {
  padding-top: 50%;
}
.long-copy-container main .content .img-container-ratio-3-2 {
  max-width: 390px;
}
.long-copy-container main .content .ratio-3-2 {
  padding-top: 66.66%;
}
.long-copy-container main .content .ellipsis,
.long-copy-container main .content .question-message {
  margin-top: 40px;
}
.long-copy-container main .content .question-message {
  display: none;
}
.long-copy-container main .content .question-message h3 {
  animation: added-item-animation 0.2s cubic-bezier(0.55, -0.04, 0.91, 0.94) forwards;
  display: inline-block;
  margin: 0;
  font-weight: normal;
  background-color: #f0f1f3;
  border-radius: 10px;
  font-size: 16px;
  line-height: 20px;
  padding: 5px 10px 6px 10px;
}
.long-copy-container main .content .choices {
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  opacity: 0;
}
.long-copy-container main .content .choices h6 {
  color: #ccced3;
  font-size: 11px;
  margin: 18px 0 0 0;
  display: inline-block;
  font-weight: normal;
}
.long-copy-container main .content .choices button {
  margin-top: 5px;
  display: block;
  color: #009B77;
  border-radius: 19.5px;
  border: 1px solid #009B77;
  font-size: 16px;
  line-height: 19px;
  padding: 10px 18px;
}
.long-copy-container main .content .fadeOut {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 60px;
  background: linear-gradient(rgba(255, 255, 255, 0), #ffffff);
  opacity: 0;
  animation: opacity-animation 300ms 1200ms forwards;
}
@keyframes ellipsisActions {
  0% {
    opacity: 0;
  }
  20% {
    opacity: 1;
  }
  80% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes added-item-animation {
  0% {
    opacity: 0;
    transform: scale(0.3);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}
.long-copy-container main .content .ellipsis {
  border-radius: 10px;
  background-color: #f0f1f3;
  display: flex;
  justify-content: center;
  width: 55px;
  height: 31px;
  align-items: center;
  opacity: 0;
  animation: ellipsisActions 2s 1s forwards;
}
.long-copy-container main .content .ellipsis span {
  height: 7px;
  width: 7px;
  border-radius: 50%;
  margin-right: 4px;
}
.long-copy-container main .content .ellipsis span:nth-child(1) {
  background-color: #54636d;
}
.long-copy-container main .content .ellipsis span:nth-child(2) {
  background-color: rgba(84, 99, 109, 0.5);
}
.long-copy-container main .content .ellipsis span:nth-child(3) {
  background-color: rgba(84, 99, 109, 0.3);
  margin-right: 0;
}
.long-copy-container main h1 {
  font-size: 24px;
  line-height: 29px;
}
.long-copy-container main h2 {
  font-size: 18px;
  line-height: 22px;
}
.long-copy-container main h3 {
  font-size: 16px;
  line-height: 22px;
}
.long-copy-container main p {
  font-size: 16px;
  line-height: 22px;
}
.long-copy-container main strong,
.long-copy-container main em {
  font-size: inherit;
}
.long-copy-container main blockquote {
  font-style: italic;
  font-size: 18px;
  line-height: 24px;
  padding-left: 15px;
  border-left: #B2E1D6 solid 5px;
  margin-left: 0;
}
.long-copy-container main .caption {
  font-size: 12px;
  line-height: 15px;
  color: #aaacb2;
}
.long-copy-container main .caption-figure {
  font-size: 13px;
  line-height: 15px;
  color: #aaacb2;
  margin: 10px 0 20px;
}
.long-copy-container main a {
  font-size: 16px;
  line-height: 20px;
  color: #009B77;
  cursor: pointer;
}
.long-copy-container main a i {
  position: relative;
  top: 6px;
  padding-right: 10px;
}
.long-copy-container.fade-enter {
  right: -100%;
  transition: right 0.6s;
}
.long-copy-container.fade-enter-active {
  right: 0;
}
.long-copy-container.fade-exit {
  right: 0;
  transition: right 0.6s;
}
.long-copy-container.fade-exit-active {
  right: -100%;
}
.long-copy-container .flex-table {
  display: flex;
  flex-wrap: wrap;
  overflow-x: auto;
  max-width: 700px;
  padding: 10px 0px;
}
.long-copy-container .flex-table .flex-row {
  display: flex;
  background-color: #ffffff;
  color: #494d5b;
  border-left: 1px solid #f0f1f3;
  border-bottom: 1px solid #f0f1f3;
}
.long-copy-container .flex-table .flex-row.h {
  background-color: #f0f1f3;
  color: #494d5b;
  border-bottom: 1px solid #ccced3;
}
.long-copy-container .flex-table .flex-row.h .flex-cell {
  border-right: 1px solid #ffffff;
}
.long-copy-container .flex-table .flex-row.h .flex-cell:last-of-type {
  border-right: 1px solid #f0f1f3;
}
.long-copy-container .flex-table .flex-row .flex-cell {
  min-width: 144px;
  max-width: 144px;
  border-right: 1px solid #f0f1f3;
  padding: 5px 10px;
  font-size: 0.7em;
  box-sizing: border-box;
}
.long-copy-container .flex-table .flex-row .flex-cell.xl {
  min-width: 288px;
  max-width: 288px;
}
.long-copy-container .flex-table .flex-row .flex-cell.xl-2 {
  min-width: 576px;
  max-width: 576px;
}
.long-copy-container .flex-table .flex-row .flex-cell.xl-3 {
  min-width: 864px;
  max-width: 864px;
}
.long-copy-container .flex-table .flex-row .flex-cell.xl-4 {
  min-width: 1152px;
  max-width: 1152px;
}
.long-copy-container .flex-table .flex-row .flex-cell.xl-5 {
  min-width: 1440px;
  max-width: 1440px;
}
.long-copy-container .flex-table .flex-row .flex-cell.l {
  min-width: 192px;
  max-width: 192px;
}
.long-copy-container .flex-table .flex-row .flex-cell.l-2 {
  min-width: 384px;
  max-width: 384px;
}
.long-copy-container .flex-table .flex-row .flex-cell.l-3 {
  min-width: 576px;
  max-width: 576px;
}
.long-copy-container .flex-table .flex-row .flex-cell.l-4 {
  min-width: 768px;
  max-width: 768px;
}
.long-copy-container .flex-table .flex-row .flex-cell.l-5 {
  min-width: 960px;
  max-width: 960px;
}
.long-copy-container .flex-table .flex-row .flex-cell.m {
  min-width: 144px;
  max-width: 144px;
}
.long-copy-container .flex-table .flex-row .flex-cell.m-2 {
  min-width: 288px;
  max-width: 288px;
}
.long-copy-container .flex-table .flex-row .flex-cell.m-3 {
  min-width: 432px;
  max-width: 432px;
}
.long-copy-container .flex-table .flex-row .flex-cell.m-4 {
  min-width: 576px;
  max-width: 576px;
}
.long-copy-container .flex-table .flex-row .flex-cell.m-5 {
  min-width: 720px;
  max-width: 720px;
}
.long-copy-container .flex-table .flex-row .flex-cell.s {
  min-width: 96px;
  max-width: 96px;
}
.long-copy-container .flex-table .flex-row .flex-cell.s-2 {
  min-width: 192px;
  max-width: 192px;
}
.long-copy-container .flex-table .flex-row .flex-cell.s-3 {
  min-width: 288px;
  max-width: 288px;
}
.long-copy-container .flex-table .flex-row .flex-cell.s-4 {
  min-width: 384px;
  max-width: 384px;
}
.long-copy-container .flex-table .flex-row .flex-cell.s-5 {
  min-width: 480px;
  max-width: 480px;
}
.long-copy-container .flex-table .flex-row .flex-cell.xs {
  min-width: 72px;
  max-width: 72px;
}
.long-copy-container .flex-table .flex-row .flex-cell.xs-2 {
  min-width: 144px;
  max-width: 144px;
}
.long-copy-container .flex-table .flex-row .flex-cell.xs-3 {
  min-width: 216px;
  max-width: 216px;
}
.long-copy-container .flex-table .flex-row .flex-cell.xs-4 {
  min-width: 288px;
  max-width: 288px;
}
.long-copy-container .flex-table .flex-row .flex-cell.xs-5 {
  min-width: 360px;
  max-width: 360px;
}
.long-copy-container .flex-table .flex-row .flex-cell.i {
  font-style: italic;
}
.long-copy-container .flex-table .flex-row .flex-cell.b {
  font-weight: bold;
}

/* Color Pallete */
/* Color Role Definitions */
/* Font Size Definitions */
/*0.9rem;*/
.content-editable {
  position: relative;
  background-color: #ffffff;
}
.content-editable__highlight {
  margin-left: -3px;
  margin-right: -3px;
  background-color: rgba(0, 155, 119, 0.2);
  border: 2px solid transparent;
  border-left-width: 3px;
  border-right-width: 3px;
  border-radius: 5px;
}
.content-editable__input {
  padding-top: 4px;
  padding-bottom: 3px;
  box-sizing: border-box;
  height: 100%;
  overflow: auto;
  color: #494d5b;
  position: relative;
  outline: none;
  padding-left: 6px;
  padding-right: 6px;
  font-size: 24px;
  font-weight: bold;
}
.content-editable__placeholder {
  position: absolute;
  padding-left: 6px;
  padding-right: 6px;
  font-size: 24px;
  font-weight: bold;
  color: #f0f1f3;
}

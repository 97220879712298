/* Color Pallete */
/* Color Role Definitions */
/* Font Size Definitions */
/*0.9rem;*/
.document-container {
  position: relative;
  z-index: 21;
  background-color: #ffffff;
  height: 100vh;
}
.document-container header {
  box-shadow: 0 4px 4px -1px rgba(0, 0, 0, 0.19);
  padding: 14px;
  position: relative;
  background: #ffffff;
  transition: all 200ms ease-in-out;
  min-height: 98px;
  box-sizing: border-box;
}
.document-container header.small-header {
  min-height: unset;
  position: fixed;
  top: -100%;
  background-color: #ffffff;
  z-index: 1;
  width: 100%;
  padding: 13px;
}
.document-container header.small-header h1 {
  display: inline;
  font-size: 16px;
  line-height: 18px;
}
.document-container header.small-header .back {
  display: inline;
  margin-bottom: 0;
  bottom: -5px;
  left: -5px;
}
.document-container header h1 {
  margin: 0;
  font-size: 24px;
  line-height: 28px;
}
.document-container header .back {
  color: #009B77;
  padding: 0;
  position: relative;
  left: -11px;
  bottom: 4px;
  margin-bottom: 9px;
  cursor: pointer;
}
.document-container header .spike {
  position: absolute;
  top: 0;
  right: 0;
  width: 44px;
  height: 100%;
  cursor: pointer;
}
.document-container header .spike div {
  position: absolute;
  top: 50%;
  right: 20px;
  width: 5px;
  height: 40px;
  border-radius: 2.5px;
  background: #ccced3;
  transform: translateY(-50%);
}
.document-container header .spike:hover div {
  animation: handle 700ms ease-in-out;
}
.document-container header > div {
  margin: 0 auto 0 auto;
  max-width: 660px;
  position: relative;
}
.document-container .background-menu {
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  background: #494d5b;
  z-index: -1;
  box-shadow: 0 4px 4px -1px rgba(0, 0, 0, 0.19);
  width: 100%;
  height: 100%;
  -webkit-transition: all 200ms ease-in-out;
  transition: all 200ms ease-in-out;
}
.document-container .background-menu ul {
  width: 100%;
  list-style: none;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-right: 10px;
}
.document-container .background-menu ul li {
  margin: 0;
  color: white;
  font-size: 12px;
  width: 64px;
  text-align: center;
  font-weight: lighter;
  cursor: pointer;
}
.document-container .background-menu i {
  display: block;
  margin-bottom: 10px;
}
.document-container main,
.document-container .main {
  padding: 14px;
  position: relative;
}
.document-container main .content,
.document-container .main .content {
  max-width: 680px;
  margin: 0 auto 0 auto;
  overflow: hidden;
  position: relative;
}
.document-container main .content .iframe-video-wrapper,
.document-container .main .content .iframe-video-wrapper {
  padding: 56.25% 0 0 0;
  position: relative;
  margin: 13px 0 20px;
}
.document-container main .content .iframe-video-wrapper iframe,
.document-container .main .content .iframe-video-wrapper iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: opacity 400ms;
}
.document-container main .content .iframe-video-wrapper iframe.load,
.document-container .main .content .iframe-video-wrapper iframe.load {
  opacity: 1;
}
.document-container main .content ul,
.document-container .main .content ul,
.document-container main .content ol,
.document-container .main .content ol {
  line-height: 22px;
}
.document-container main .content button,
.document-container .main .content button {
  display: block;
  margin: auto;
}
.document-container main .content p,
.document-container .main .content p {
  font-size: 16px;
  line-height: 22px;
  margin: 20px 0;
}
.document-container main .content .flex-table,
.document-container .main .content .flex-table {
  display: flex;
  flex-wrap: wrap;
  overflow-x: auto;
  max-width: 700px;
  padding: 10px 0px;
}
.document-container main .content .flex-table .flex-row,
.document-container .main .content .flex-table .flex-row {
  display: flex;
  background-color: #ffffff;
  color: #494d5b;
  border-left: 1px solid #f0f1f3;
  border-bottom: 1px solid #f0f1f3;
}
.document-container main .content .flex-table .flex-row.h,
.document-container .main .content .flex-table .flex-row.h {
  background-color: #f0f1f3;
  color: #494d5b;
  border-bottom: 1px solid #ccced3;
}
.document-container main .content .flex-table .flex-row.h .flex-cell,
.document-container .main .content .flex-table .flex-row.h .flex-cell {
  border-right: 1px solid #ffffff;
}
.document-container main .content .flex-table .flex-row.h .flex-cell:last-of-type,
.document-container .main .content .flex-table .flex-row.h .flex-cell:last-of-type {
  border-right: 1px solid #f0f1f3;
}
.document-container main .content .flex-table .flex-row .flex-cell,
.document-container .main .content .flex-table .flex-row .flex-cell {
  min-width: 144px;
  max-width: 144px;
  border-right: 1px solid #f0f1f3;
  padding: 5px 10px;
  font-size: 0.7em;
  box-sizing: border-box;
}
.document-container main .content .flex-table .flex-row .flex-cell.xl,
.document-container .main .content .flex-table .flex-row .flex-cell.xl {
  min-width: 288px;
  max-width: 288px;
}
.document-container main .content .flex-table .flex-row .flex-cell.xl-2,
.document-container .main .content .flex-table .flex-row .flex-cell.xl-2 {
  min-width: 576px;
  max-width: 576px;
}
.document-container main .content .flex-table .flex-row .flex-cell.xl-3,
.document-container .main .content .flex-table .flex-row .flex-cell.xl-3 {
  min-width: 864px;
  max-width: 864px;
}
.document-container main .content .flex-table .flex-row .flex-cell.xl-4,
.document-container .main .content .flex-table .flex-row .flex-cell.xl-4 {
  min-width: 1152px;
  max-width: 1152px;
}
.document-container main .content .flex-table .flex-row .flex-cell.xl-5,
.document-container .main .content .flex-table .flex-row .flex-cell.xl-5 {
  min-width: 1440px;
  max-width: 1440px;
}
.document-container main .content .flex-table .flex-row .flex-cell.l,
.document-container .main .content .flex-table .flex-row .flex-cell.l {
  min-width: 192px;
  max-width: 192px;
}
.document-container main .content .flex-table .flex-row .flex-cell.l-2,
.document-container .main .content .flex-table .flex-row .flex-cell.l-2 {
  min-width: 384px;
  max-width: 384px;
}
.document-container main .content .flex-table .flex-row .flex-cell.l-3,
.document-container .main .content .flex-table .flex-row .flex-cell.l-3 {
  min-width: 576px;
  max-width: 576px;
}
.document-container main .content .flex-table .flex-row .flex-cell.l-4,
.document-container .main .content .flex-table .flex-row .flex-cell.l-4 {
  min-width: 768px;
  max-width: 768px;
}
.document-container main .content .flex-table .flex-row .flex-cell.l-5,
.document-container .main .content .flex-table .flex-row .flex-cell.l-5 {
  min-width: 960px;
  max-width: 960px;
}
.document-container main .content .flex-table .flex-row .flex-cell.m,
.document-container .main .content .flex-table .flex-row .flex-cell.m {
  min-width: 144px;
  max-width: 144px;
}
.document-container main .content .flex-table .flex-row .flex-cell.m-2,
.document-container .main .content .flex-table .flex-row .flex-cell.m-2 {
  min-width: 288px;
  max-width: 288px;
}
.document-container main .content .flex-table .flex-row .flex-cell.m-3,
.document-container .main .content .flex-table .flex-row .flex-cell.m-3 {
  min-width: 432px;
  max-width: 432px;
}
.document-container main .content .flex-table .flex-row .flex-cell.m-4,
.document-container .main .content .flex-table .flex-row .flex-cell.m-4 {
  min-width: 576px;
  max-width: 576px;
}
.document-container main .content .flex-table .flex-row .flex-cell.m-5,
.document-container .main .content .flex-table .flex-row .flex-cell.m-5 {
  min-width: 720px;
  max-width: 720px;
}
.document-container main .content .flex-table .flex-row .flex-cell.s,
.document-container .main .content .flex-table .flex-row .flex-cell.s {
  min-width: 96px;
  max-width: 96px;
}
.document-container main .content .flex-table .flex-row .flex-cell.s-2,
.document-container .main .content .flex-table .flex-row .flex-cell.s-2 {
  min-width: 192px;
  max-width: 192px;
}
.document-container main .content .flex-table .flex-row .flex-cell.s-3,
.document-container .main .content .flex-table .flex-row .flex-cell.s-3 {
  min-width: 288px;
  max-width: 288px;
}
.document-container main .content .flex-table .flex-row .flex-cell.s-4,
.document-container .main .content .flex-table .flex-row .flex-cell.s-4 {
  min-width: 384px;
  max-width: 384px;
}
.document-container main .content .flex-table .flex-row .flex-cell.s-5,
.document-container .main .content .flex-table .flex-row .flex-cell.s-5 {
  min-width: 480px;
  max-width: 480px;
}
.document-container main .content .flex-table .flex-row .flex-cell.xs,
.document-container .main .content .flex-table .flex-row .flex-cell.xs {
  min-width: 72px;
  max-width: 72px;
}
.document-container main .content .flex-table .flex-row .flex-cell.xs-2,
.document-container .main .content .flex-table .flex-row .flex-cell.xs-2 {
  min-width: 144px;
  max-width: 144px;
}
.document-container main .content .flex-table .flex-row .flex-cell.xs-3,
.document-container .main .content .flex-table .flex-row .flex-cell.xs-3 {
  min-width: 216px;
  max-width: 216px;
}
.document-container main .content .flex-table .flex-row .flex-cell.xs-4,
.document-container .main .content .flex-table .flex-row .flex-cell.xs-4 {
  min-width: 288px;
  max-width: 288px;
}
.document-container main .content .flex-table .flex-row .flex-cell.xs-5,
.document-container .main .content .flex-table .flex-row .flex-cell.xs-5 {
  min-width: 360px;
  max-width: 360px;
}
.document-container main .content .flex-table .flex-row .flex-cell.i,
.document-container .main .content .flex-table .flex-row .flex-cell.i {
  font-style: italic;
}
.document-container main .content .flex-table .flex-row .flex-cell.b,
.document-container .main .content .flex-table .flex-row .flex-cell.b {
  font-weight: bold;
}
.document-container main .content .img,
.document-container .main .content .img {
  width: 100%;
  background-color: #d8d8d8;
  border-radius: 10px;
}
.document-container main .content .img-container-ratio-1-1,
.document-container .main .content .img-container-ratio-1-1,
.document-container main .content .img-container-ratio-2-1,
.document-container .main .content .img-container-ratio-2-1,
.document-container main .content .img-container-ratio-3-2,
.document-container .main .content .img-container-ratio-3-2 {
  margin: 10px 0;
}
.document-container main .content .img-container-ratio-1-1,
.document-container .main .content .img-container-ratio-1-1 {
  max-width: 260px;
  display: inline-block;
  width: 100%;
  margin-right: 20px;
}
@media screen and (max-width: 375px) {
  .document-container main .content .img-container-ratio-1-1,
  .document-container .main .content .img-container-ratio-1-1 {
    margin-right: 0;
  }
}
.document-container main .content .figure-header,
.document-container .main .content .figure-header {
  font-size: 16px;
  line-height: 19px;
  font-weight: bold;
  margin: 30px 0 10px;
}
.document-container main .content .figure-container,
.document-container .main .content .figure-container {
  display: inline-block;
  margin-bottom: 10px;
  margin-right: 20px;
}
@media screen and (max-width: 375px) {
  .document-container main .content .figure-container,
  .document-container .main .content .figure-container {
    margin-right: 0;
  }
}
.document-container main .content .figure-title,
.document-container .main .content .figure-title {
  margin: 0 0 10px 0;
}
.document-container main .content .img-container-ratio-2-1,
.document-container .main .content .img-container-ratio-2-1 {
  max-width: 520px;
}
.document-container main .content .img-container-ratio-3-2,
.document-container .main .content .img-container-ratio-3-2 {
  max-width: 390px;
}
.document-container main .content .ellipsis,
.document-container .main .content .ellipsis,
.document-container main .content .question-message,
.document-container .main .content .question-message {
  margin-top: 40px;
}
.document-container main .content .question-message,
.document-container .main .content .question-message {
  display: none;
}
.document-container main .content .question-message h3,
.document-container .main .content .question-message h3 {
  animation: added-item-animation 0.2s cubic-bezier(0.55, -0.04, 0.91, 0.94) forwards;
  display: inline-block;
  margin: 0;
  font-weight: normal;
  background-color: #f0f1f3;
  border-radius: 10px;
  font-size: 16px;
  line-height: 20px;
  padding: 5px 10px 6px 10px;
}
.document-container main .content .choices,
.document-container .main .content .choices {
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  opacity: 0;
}
.document-container main .content .choices h6,
.document-container .main .content .choices h6 {
  color: #ccced3;
  font-size: 11px;
  margin: 18px 0 0 0;
  display: inline-block;
  font-weight: normal;
}
.document-container main .content .choices button,
.document-container .main .content .choices button {
  margin-top: 5px;
  display: block;
  color: #009B77;
  border-radius: 19.5px;
  border: 1px solid #009B77;
  font-size: 16px;
  line-height: 19px;
  padding: 10px 18px;
}
.document-container main .content .fadeOut,
.document-container .main .content .fadeOut {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 60px;
  background: linear-gradient(rgba(255, 255, 255, 0), #ffffff);
  opacity: 0;
  animation: opacity-animation 300ms 1200ms forwards;
}
@keyframes ellipsisActions {
  0% {
    opacity: 0;
  }
  20% {
    opacity: 1;
  }
  80% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes added-item-animation {
  0% {
    opacity: 0;
    transform: scale(0.3);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}
.document-container main .content .ellipsis,
.document-container .main .content .ellipsis {
  border-radius: 10px;
  background-color: #f0f1f3;
  display: flex;
  justify-content: center;
  width: 55px;
  height: 31px;
  align-items: center;
  opacity: 0;
  animation: ellipsisActions 2s 1s forwards;
}
.document-container main .content .ellipsis span,
.document-container .main .content .ellipsis span {
  height: 7px;
  width: 7px;
  border-radius: 50%;
  margin-right: 4px;
}
.document-container main .content .ellipsis span:nth-child(1),
.document-container .main .content .ellipsis span:nth-child(1) {
  background-color: #54636d;
}
.document-container main .content .ellipsis span:nth-child(2),
.document-container .main .content .ellipsis span:nth-child(2) {
  background-color: rgba(84, 99, 109, 0.5);
}
.document-container main .content .ellipsis span:nth-child(3),
.document-container .main .content .ellipsis span:nth-child(3) {
  background-color: rgba(84, 99, 109, 0.3);
  margin-right: 0;
}
.document-container main .content h1,
.document-container .main .content h1 {
  font-size: 24px;
  line-height: 29px;
}
.document-container main .content h2,
.document-container .main .content h2 {
  font-size: 18px;
  line-height: 22px;
}
.document-container main .content h3,
.document-container .main .content h3 {
  font-size: 16px;
  line-height: 22px;
}
.document-container main .content p,
.document-container .main .content p {
  font-size: 16px;
  line-height: 22px;
}
.document-container main .content strong,
.document-container .main .content strong,
.document-container main .content em,
.document-container .main .content em {
  font-size: inherit;
}
.document-container main .content blockquote,
.document-container .main .content blockquote {
  font-style: italic;
  font-size: 18px;
  line-height: 24px;
  padding-left: 15px;
  border-left: #B2E1D6 solid 5px;
  margin-left: 0;
}
.document-container main .content .caption,
.document-container .main .content .caption {
  font-size: 12px;
  line-height: 15px;
  color: #aaacb2;
}
.document-container main .content .caption-figure,
.document-container .main .content .caption-figure {
  font-size: 13px;
  line-height: 15px;
  color: #aaacb2;
  margin: 10px 0 20px;
}
.document-container main .content a,
.document-container .main .content a {
  font-size: 16px;
  line-height: 20px;
  color: #009B77;
  cursor: pointer;
}
.document-container main .content a i,
.document-container .main .content a i {
  position: relative;
  top: 6px;
  padding-right: 10px;
}
.document-container main .content.fade-enter,
.document-container .main .content.fade-enter {
  right: -100%;
  transition: right 0.6s;
}
.document-container main .content.fade-enter-active,
.document-container .main .content.fade-enter-active {
  right: 0;
}
.document-container main .content.fade-exit,
.document-container .main .content.fade-exit {
  right: 0;
  transition: right 0.6s;
}
.document-container main .content.fade-exit-active,
.document-container .main .content.fade-exit-active {
  right: -100%;
}
.document-container main h1,
.document-container .main h1 {
  font-size: 24px;
  line-height: 29px;
}
.document-container main h2,
.document-container .main h2 {
  font-size: 18px;
  line-height: 22px;
}
.document-container main h3,
.document-container .main h3 {
  font-size: 16px;
  line-height: 22px;
}
.document-container main p,
.document-container .main p {
  font-size: 16px;
  line-height: 22px;
}
.document-container main strong,
.document-container .main strong,
.document-container main em,
.document-container .main em {
  font-size: inherit;
}
.document-container main blockquote,
.document-container .main blockquote {
  font-style: italic;
  font-size: 18px;
  line-height: 24px;
  padding-left: 15px;
  border-left: #B2E1D6 solid 5px;
  margin-left: 0;
}
.document-container main .caption,
.document-container .main .caption {
  font-size: 12px;
  line-height: 15px;
  color: #aaacb2;
}
.document-container main a,
.document-container .main a {
  font-size: 16px;
  line-height: 20px;
  color: #009B77;
  cursor: pointer;
}
.document-container main a i,
.document-container .main a i {
  position: relative;
  top: 6px;
  padding-right: 10px;
}
.document-container.fade-enter {
  right: -100%;
  transition: right 0.6s;
}
.document-container.fade-enter-active {
  right: 0;
}
.document-container.fade-exit {
  right: 0;
  transition: right 0.6s;
}
.document-container.fade-exit-active {
  right: -100%;
}

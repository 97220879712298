/* Color Pallete */
/* Color Role Definitions */
/* Font Size Definitions */
/*0.9rem;*/
.errorContainer {
  position: absolute;
  top: -10px;
  left: 0;
  width: 100%;
  z-index: 9;
  overflow: hidden;
  border-top-right-radius: 9px;
  border-top-left-radius: 9px;
}
.errorContainer .error {
  background-color: #009B77;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  box-sizing: border-box;
  min-height: 50px;
}
.errorContainer .error .errorText {
  width: 100%;
  font-size: 0.875rem;
  line-height: 1.25rem;
  color: white;
  text-align: center;
  font-weight: 400;
  padding: 0 24px;
  white-space: pre-line;
}
.errorContainer .error .errorCloseBtn {
  position: absolute;
  color: white;
  right: 10px;
  cursor: pointer;
  top: 10px;
}
.errorContainer .error .closeBtn {
  position: relative;
  color: pink;
  right: 0px;
  top: 0px;
  font-size: 14px;
  cursor: pointer;
}
.errorContainer .error .closeBtn-error {
  color: #ffffff;
}
.error-message-enter {
  top: -60px;
}
.error-message-enter-active {
  top: 0;
  transition: top 200ms ease-in-out;
}
.error-message-exit {
  top: 0;
  height: 50px;
}
.error-message-exit-active {
  top: -60px;
  transition: top 200ms ease-in-out;
}


@font-face {
  font-family: 'xircles-icons';
  src:  url('fonts/xircles-icons.eot?kqg2s8');
  src:  url('fonts/xircles-icons.eot?kqg2s8#iefix') format('embedded-opentype'),
  url('fonts/xircles-icons.ttf?kqg2s8') format('truetype'),
  url('fonts/xircles-icons.woff?kqg2s8') format('woff'),
  url('fonts/xircles-icons.svg?kqg2s8#xircles-icons') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'xircles-icons' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-up:before {
  content: "\e903";
}
.icon-circle:before {
  content: "\e901";
}
.icon-check-circle:before {
  content: "\e902";
}
.icon-send:before {
  content: "\e900";
}
.icon-open-in-browser:before {
  content: "\e904";
}
.icon-select-suggestion:before {
  content: url('../images/insert-question.png');
}

/* Color Pallete */
/* Color Role Definitions */
/* Font Size Definitions */
/*0.9rem;*/
@media screen and (min-width: 1035px) {
  .questionPage .scrollPane .content-panel {
    max-width: 1035px;
  }
}
@media screen and (min-width: 768px) and (max-width: 1034px) {
  .questionPage .scrollPane .content-panel {
    max-width: 690px;
  }
}
@media screen and (max-width: 767px) {
  .questionPage .scrollPane .content-panel {
    max-width: 335px;
  }
}
.questionPage .scrollPane .content-panel {
  margin: 12px auto 60px auto;
}
.questionPage .scrollPane .content-panel .row {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}
.questionPage .scrollPane .content-panel .row .large-dummy-block,
.questionPage .scrollPane .content-panel .row .medium-dummy-block,
.questionPage .scrollPane .content-panel .row .small-dummy-block {
  background-color: #ffffff;
  border-radius: 10px;
  margin-top: 20px;
}
.questionPage .scrollPane .content-panel .row .dummy-phrase {
  font-size: 24px;
  color: #4a4c5b;
  text-align: center;
  margin: 40px 0 40px 0;
  font-weight: bold;
}
.questionPage .scrollPane .content-panel .row .large-dummy-block {
  display: flex;
  flex-wrap: wrap;
}
.questionPage .scrollPane .content-panel .row .large-dummy-block .content {
  width: 335px;
  height: 262px;
}
@media screen and (min-width: 768px) {
  .questionPage .scrollPane .content-panel .row .large-dummy-block .content:first-child {
    margin-right: 5px;
  }
  .questionPage .scrollPane .content-panel .row .large-dummy-block .content:last-child {
    margin-left: 5px;
  }
}
.questionPage .scrollPane .content-panel .row .medium-dummy-block {
  width: 335px;
  height: 262px;
}
.questionPage .scrollPane .content-panel .row .small-dummy-block {
  width: 334px;
  height: 166px;
}
@media screen and (min-width: 1035px) {
  .questionPage .scrollPane .content-panel .row .dummy-phrase {
    width: 50%;
  }
}
@media screen and (min-width: 768px) and (max-width: 1034px) {
  .questionPage .scrollPane .content-panel .row .dummy-phrase {
    width: 80%;
  }
}
@media screen and (max-width: 767px) {
  .questionPage .scrollPane .content-panel .row .dummy-phrase {
    width: 100%;
  }
  .questionPage .scrollPane .content-panel .row .large-dummy-block .content {
    width: 335px;
  }
}

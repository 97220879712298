/* Color Pallete */
/* Color Role Definitions */
/* Font Size Definitions */
/*0.9rem;*/
.question .bigButtonBarWrapper {
  position: relative;
}
.question .bigButtonBarWrapper .send {
  position: absolute;
  right: -14px;
  top: -44px;
}
.question .bigButtonBar {
  border-top: 2px solid #f0f1f3;
  font-size: 13px;
  color: #aaacb2;
  position: relative;
  visibility: hidden;
  bottom: 0;
  box-sizing: border-box;
  transition: opacity 200ms ease-in-out, visibility 0s 200ms;
  opacity: 0;
  display: flex;
  flex-wrap: wrap;
}
.question .bigButtonBar > div {
  border-top: 0.5px solid #ccced3;
  padding-top: 9px;
  position: static;
  left: 16px;
  right: 16px;
  width: 100%;
}
.question .bigButtonBar .short-cut {
  display: inline-block;
  width: 120px;
  cursor: pointer;
  border: none;
}
@media only screen and (max-width: 768px) {
  .question .bigButtonBar {
    width: 100%;
    border-top-width: 1px;
  }
}
.questionFixed .question .bigButtonBarVisible {
  opacity: 0;
  margin-bottom: 31px;
}
.questionFixed .question .bigButtonBarVisible .send {
  display: none;
}
@media only screen and (max-width: 1035px) {
  .questionFixed .question .bigButtonBarVisible {
    margin-bottom: 38px;
  }
}
@media only screen and (max-width: 768px) {
  .questionFixed .question .bigButtonBarVisible {
    width: calc(100% - 32px);
    margin-bottom: 61px;
  }
}
@media only screen and (max-width: 376px) {
  .questionFixed .question .bigButtonBarVisible {
    margin-bottom: 23px;
  }
}
.questionFixed .question--first-time .bigButtonBarVisible {
  margin-bottom: 0;
}
@media only screen and (max-width: 768px) {
  .questionFixed .question--first-time .bigButtonBarVisible {
    margin-bottom: 28px;
  }
}
@media only screen and (max-width: 434px) {
  .questionFixed .question--first-time .bigButtonBarVisible {
    margin-bottom: 5px;
  }
}
@media only screen and (max-width: 365px) {
  .questionFixed .question--first-time .bigButtonBarVisible {
    margin-bottom: -15px;
  }
}
@media only screen and (max-width: 375px) {
  .question .bigButtonBar .short-cut {
    width: 90px;
  }
}

/* Color Pallete */
/* Color Role Definitions */
/* Font Size Definitions */
/*0.9rem;*/
.route-section > div:nth-of-type(1) {
  position: relative;
}
.loading-spinner {
  position: absolute;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  border-radius: 10px;
  background-color: rgba(255, 255, 255, 0.66);
}
.loading-spinner__document-page {
  align-items: flex-start;
}
.loading-spinner__document-page--loaded {
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s 0.2s, opacity 0.2s ease-in-out;
}
.loading-spinner__document-page .spinner {
  top: unset;
  margin-top: 70px;
}
.spinner {
  position: relative;
  top: -15%;
  margin: 10px;
  animation: rotate 1s linear infinite;
  width: 67px;
  height: 67px;
}
.spinner-dot {
  stroke: #494d5b;
  fill: #494d5b;
  animation: skew 1s linear infinite alternate;
}
.spinner-border {
  fill: transparent;
  stroke-width: 5px;
}
.path {
  stroke-dasharray: 170;
  stroke-dashoffset: -33;
}
@keyframes rotate {
  to {
    transform: rotate(-360deg);
  }
}
@keyframes skew {
  from {
    transform: skewX(10deg);
  }
  to {
    transform: skewX(40deg);
  }
}

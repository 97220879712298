/* Color Pallete */
/* Color Role Definitions */
/* Font Size Definitions */
/*0.9rem;*/
.question .suggestions {
  overflow: hidden;
  position: absolute;
  border-radius: 5px;
  box-shadow: 0 2px 14px 0 rgba(0, 0, 0, 0.21);
  background: white;
  margin: 8px -10px 0;
  padding: 10px;
  width: 100%;
  min-height: 121px;
  transition: all 1ms ease-in-out;
  opacity: 0;
  z-index: 100;
}
.question .suggestions ul {
  list-style: none;
  padding: 0;
  margin: 10px auto 0;
  max-width: 700px;
}
.question .suggestions header {
  max-width: 682px;
  background: white;
  font-family: "Roboto", sans-serif;
  font-size: 18px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.17;
  letter-spacing: normal;
  color: #009B77;
  transition: all 200ms 200ms ease-in-out;
  opacity: 0;
  margin: 0;
}
.question .suggestions.open {
  opacity: 1;
}
.question .suggestions.move {
  height: 0;
  opacity: 1;
}
.question .suggestions.move header {
  opacity: 1;
}
.question .suggestions .close-button-wrapper {
  color: #ccced3;
  position: absolute;
  top: 0;
  right: 0;
}
.question .suggestions--fixed {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  margin: 5px;
  width: unset;
}
.question .suggestions-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: none;
}
.question .suggestions-overlay-shown {
  display: block;
}

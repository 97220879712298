/* Color Pallete */
/* Color Role Definitions */
/* Font Size Definitions */
/*0.9rem;*/
.contents {
  position: fixed;
}
.contents.first-page {
  right: calc(50% - 330px);
}
@media screen and (max-width: 767px) {
  .contents.first-page {
    right: 40px;
  }
}
.contents.hide-contents {
  display: none;
}
.contents .contents-icon {
  height: 30px;
  background-color: #009B77;
  border-radius: 50%;
  color: #7FCDBB;
  cursor: pointer;
  padding: 3px 3px 0 3px;
  margin: 0;
  z-index: 2;
}
.contents .contents-icon,
.contents > ul,
.contents > p {
  position: absolute;
}
.contents > ul:nth-of-type(1) {
  max-height: 85vh;
  overflow-y: scroll;
}
.contents p {
  box-sizing: border-box;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  right: -13px;
  margin: 0;
  font-size: 13px;
  color: #009B77;
  line-height: 30px;
  background-color: #B2E1D6;
  font-weight: bold;
  padding: 0 21px 0 13px;
  border-radius: 15px 0 0 15px;
  transition: opacity 200ms ease-in-out;
  width: max-content;
  max-width: 355px;
  word-wrap: break-word;
}
@media screen and (max-width: 376px) {
  .contents p {
    max-width: 75vw;
  }
}
.contents > ul {
  box-sizing: border-box;
  margin: 0 0 30px 0;
  right: -30px;
  list-style: none;
  background-color: #B2E1D6;
  border-radius: 15px;
  padding: 10px 0 10px 15px;
  z-index: 1;
  line-height: 32px;
  width: max-content;
  max-width: 355px;
  word-wrap: break-word;
}
@media screen and (max-width: 376px) {
  .contents > ul {
    max-width: 90vw;
  }
}
.contents > ul .active {
  color: #009B77;
  font-weight: bold;
}
.contents > ul li {
  padding: 0 50px 0 0;
  max-width: 320px;
  display: flex;
  align-items: center;
}
.contents > ul li a {
  max-width: 100%;
  display: inline-block;
  cursor: pointer;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.contents > ul ul {
  list-style: none;
  border-left: #7FCDBB solid 2px;
  padding-left: 20px;
}
.fade-enter div.contents {
  right: calc(-50% - 330px);
  transition: right 0.6s;
}
@media screen and (max-width: 767px) {
  .fade-enter div.contents {
    right: calc(-100% + 40px);
  }
}
.fade-enter-active div.contents {
  right: calc(50% - 330px);
}
@media screen and (max-width: 767px) {
  .fade-enter-active div.contents {
    right: 40px;
  }
}
.fade-exit .contents {
  right: calc(50% - 330px);
  transition: right 0.6s;
}
@media screen and (max-width: 767px) {
  .fade-exit .contents {
    right: 40px;
  }
}
.fade-exit-active .contents {
  right: calc(-50% - 330px);
}
@media screen and (max-width: 767px) {
  .fade-exit-active .contents {
    right: calc(-100% + 40px);
  }
}

/* Color Pallete */
/* Color Role Definitions */
/* Font Size Definitions */
/*0.9rem;*/
.wrapper {
  position: relative;
}
.landscape-overlay-wrap {
  position: fixed;
  z-index: 100;
  display: none;
}
#landscape-overlay {
  display: table;
  position: fixed;
  height: 100vh;
  width: 100vw;
  background: #ffffff;
  color: #494d5b;
  z-index: 10;
  text-align: center;
}
#landscape-overlay .lo-content {
  font-weight: bold;
  padding: 0 20%;
  display: table-cell;
  vertical-align: middle;
}

/* Color Pallete */
/* Color Role Definitions */
/* Font Size Definitions */
/*0.9rem;*/
@media screen and (min-width: 1035px) {
  .questionPage .scrollPane footer {
    max-width: 1035px;
  }
}
@media screen and (min-width: 768px) and (max-width: 1034px) {
  .questionPage .scrollPane footer {
    max-width: 690px;
  }
}
@media screen and (max-width: 767px) {
  .questionPage .scrollPane footer {
    max-width: 310px;
    flex-direction: column;
    align-items: flex-start;
  }
  .questionPage .scrollPane footer a {
    margin-bottom: 10px;
  }
}
.questionPage .scrollPane footer {
  border-top: 1px solid #ccced3;
  margin: 0 auto 0 auto;
  padding: 8px 0 43px 0;
  display: flex;
  justify-content: flex-start;
  font-size: 13px;
}
.questionPage .scrollPane footer a {
  color: #aaacb2;
  padding-right: 20px;
  cursor: pointer;
}
.questionPage .scrollPane footer a.name {
  font-weight: bold;
}

/* Color Pallete */
/* Color Role Definitions */
/* Font Size Definitions */
/*0.9rem;*/
.menu-panel {
  z-index: 2;
  pointer-events: none;
  position: fixed;
  right: 0;
  top: 0;
  width: 275px;
  height: 100vh;
  background: #494d5b;
  color: #ccced3;
  font-size: 18px;
  line-height: 1.17;
  letter-spacing: normal;
  font-stretch: normal;
  font-style: normal;
  font-weight: lighter;
  transition: opacity 200ms ease-in-out;
  opacity: 0;
}
.menu-panel footer {
  font-size: 13px;
  line-height: 1.63;
  position: absolute;
  bottom: 21px;
}
.menu-panel footer .menu-content-button {
  margin-top: 10px;
  position: relative;
}
.menu-panel footer .menu-content-button a {
  text-decoration: underline;
  color: #ccced3;
}
.menu-panel footer div {
  padding-left: 54px;
  margin-top: 20px;
}
.menu-panel button {
  background: #494d5b;
  color: #ffffff;
}
.menu-panel ul {
  list-style: none;
  padding-left: 54px;
  margin-top: 42px;
}
.menu-panel ul .menu-account-button {
  position: relative;
  margin-top: 33px;
}
.menu-panel ul .menu-account-button i {
  position: absolute;
  left: -33px;
  top: -1px;
  color: #ffffff;
}
.menu-panel ul .menu-account-button a {
  color: #ccced3;
}
.menu-panel.open {
  opacity: 1;
  pointer-events: all;
}
.menu-panel.open .close {
  transform: rotate(0);
  color: #ffffff;
}
.menu-panel .close {
  transition: all 200ms ease-in-out;
  transform: rotate(-90deg);
}
.menu-panel-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: none;
  z-index: 2;
}
.menu-panel-overlay__shown {
  display: block;
}

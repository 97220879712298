.splash-screen {
  box-sizing: border-box;
  width: 100vw;
  height: 100vh;
  padding-top: 133px;
  display: flex;
  justify-content: center;
  text-align: center;
}
.splash-screen__container h2 {
  line-height: 26px;
  font-size: 22px;
  font-weight: bold;
  letter-spacing: normal;
  margin: 0;
}
.splash-screen__container p {
  display: inline-block;
  width: 100%;
}
.splash-screen__container p:nth-of-type(1) {
  margin: 37px 0 0 0;
}
.splash-screen__container--outdated-chrome p:nth-of-type(1),
.splash-screen__container--outdated-chrome p:nth-of-type(2) {
  margin: 0;
}
.splash-screen__container--outdated-chrome p:nth-of-type(1) {
  margin-top: 15px;
  font-weight: bold;
}
.splash-screen__container--actual-version-chrome {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 37px;
}
.splash-screen__container--actual-version-chrome .mdc-text-field,
.splash-screen__container--actual-version-chrome button {
  width: 295px;
}
.splash-screen__container--actual-version-chrome input {
  text-align: center;
}
.splash-screen__container--actual-version-chrome__error {
  margin-top: 15px;
  color: red;
}
.splash-screen__container img {
  height: 74px;
  margin-top: 40px;
}

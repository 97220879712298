/* Color Pallete */
/* Color Role Definitions */
/* Font Size Definitions */
/*0.9rem;*/
.questionPage .panel-header .title h2 {
  font-size: 22px;
  color: #494d5b;
  margin: 20px 0 41px 0;
}
@media only screen and (max-width: 1034px) {
  .questionPage .panel-header .title h2 {
    margin-top: 21px;
    margin-bottom: 38px;
  }
}
@media only screen and (max-width: 767px) {
  .questionPage .panel-header .title h2 {
    font-size: 19px;
    margin-top: 15px;
    margin-bottom: 22px;
  }
}
.questionPage .panel-header .title__operator h2 {
  font-size: 24px;
  margin-bottom: 34px;
}
@media only screen and (max-width: 1034px) {
  .questionPage .panel-header .title__operator h2 {
    margin-bottom: 35px;
  }
}
@media only screen and (max-width: 767px) {
  .questionPage .panel-header .title__operator h2 {
    margin-bottom: 18px;
  }
}
.questionPage .left-panel.fixed .panel-header {
  xtransition: none;
}
.questionPage .left-panel.open .panel-header {
  transition: top 30ms ease-out, margin-top 200ms 30ms ease-out;
  margin-top: -410px;
  xtop: -100px;
}
.questionPage .left-panel .panel-header {
  width: 100%;
  xposition: relative;
  transition: top 30ms 200ms ease-out, margin-top 200ms ease-out;
  top: 0;
  margin-top: 0;
  display: flex;
  flex-wrap: wrap;
}
.questionPage .left-panel .panel-header .grey-top {
  width: 100vw;
  height: 35px;
  background-color: #494d5b;
}
.questionPage .left-panel .panel-header h6 {
  position: absolute;
  color: #494d5b;
  text-transform: uppercase;
  font-size: 13px;
  margin: 0;
  cursor: pointer;
}
.questionPage .left-panel .panel-header .phrase {
  left: 22px;
  top: 25px;
  cursor: text;
}
.questionPage .left-panel .panel-header .login {
  color: #009B77;
  right: 111px;
  top: 25px;
  position: fixed;
}
.questionPage .left-panel .panel-header .login__operator {
  top: 50px;
  right: 219px;
}
.questionPage .left-panel .panel-header .menu {
  position: fixed;
  right: 54px;
  top: 25px;
}
.questionPage .left-panel .panel-header .menu__operator {
  top: 50px;
  right: 162px;
}
.questionPage .left-panel .panel-header .menu-button__operator {
  top: 35px;
  right: 121px;
}
@media only screen and (max-width: 1035px) {
  .questionPage .left-panel .panel-header .menu-button__operator {
    right: 15px;
  }
}
.questionPage .left-panel .panel-header .title__operator {
  box-shadow: 0 4px 4px -1px rgba(0, 0, 0, 0.19);
}
@media only screen and (max-width: 767px) {
  .questionPage .left-panel .panel-header .phrase {
    color: #ccced3;
    font-size: 10px;
    width: 87px;
    text-align: left;
    top: 11px;
    left: 10px;
  }
  .questionPage .left-panel .panel-header .login {
    position: absolute;
    top: 15px;
    right: 55px;
  }
  .questionPage .left-panel .panel-header .menu-button {
    position: absolute;
    top: 0;
  }
  .questionPage .left-panel .panel-header .menu {
    display: none;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1034px) {
  .questionPage .left-panel .panel-header .menu {
    display: none;
  }
  .questionPage .left-panel .panel-header .login {
    position: absolute;
    right: 64px;
  }
  .questionPage .left-panel .panel-header .menu-button {
    position: absolute;
  }
  .questionPage .left-panel .panel-header .title {
    margin-top: 30px;
  }
  .questionPage .left-panel .panel-header .title h2 {
    margin-bottom: 27px;
  }
  .questionPage .left-panel .panel-header .phrase {
    left: 25px;
  }
}

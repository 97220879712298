.tabbar {
    padding: 4px 0 0 0;
    background-color: rgb(250, 251, 252);
    box-sizing: border-box;
    display: -webkit-flex;
    display: flex;
    min-height: 40px;
    -webkit-justify-content: space-between;
    justify-content: space-between;
    -webkit-align-items: center;
    align-items: center;
}

.tabbar .tab {
    width: 33.33%;
    text-align: center;
    color: rgba(110, 116, 143, 0.2);
}

.tabbar .tab:hover {
    cursor: pointer;
}
.tabbar .tab.selected, .tabbar .tab:hover,.tabbar .tab:active {
    color: rgb(0, 155, 119);
    border-bottom: 3px solid rgb(0, 155, 119);
}

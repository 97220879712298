/* Color Pallete */
/* Color Role Definitions */
/* Font Size Definitions */
/*0.9rem;*/
.video-container {
  position: relative;
  z-index: 21;
  background-color: #ffffff;
  height: 100vh;
}
.video-container .small-header {
  position: fixed;
  top: -100%;
  background-color: #ffffff;
  z-index: 1;
  width: 100%;
  padding: 13px;
}
.video-container .small-header h1 {
  display: inline;
  font-size: 16px;
  line-height: 18px;
}
.video-container .small-header .back {
  display: inline;
  margin-bottom: 0;
  bottom: -5px;
  left: -5px;
}
.video-container header {
  position: relative;
  box-shadow: 0 4px 4px -1px rgba(0, 0, 0, 0.19);
  padding: 14px;
  background: white;
  transition: all 200ms ease-in-out;
}
.video-container header h1 {
  margin: 0;
  font-size: 24px;
  line-height: 28px;
}
.video-container header .back {
  color: #009B77;
  padding: 0;
  position: relative;
  left: -11px;
  bottom: 4px;
  margin-bottom: 9px;
  cursor: pointer;
}
.video-container header > div {
  margin: 0 auto 0 auto;
  max-width: 660px;
  position: relative;
}
.video-container header .spike {
  position: absolute;
  top: 0;
  right: 0;
  width: 44px;
  height: 100%;
  cursor: pointer;
}
.video-container header .spike div {
  position: absolute;
  top: 50%;
  right: 20px;
  width: 5px;
  height: 40px;
  border-radius: 2.5px;
  background: #ccced3;
  transform: translateY(-50%);
}
.video-container header .spike:hover div {
  animation: handle 700ms ease-in-out;
}
.video-container .background-menu {
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  background: #494d5b;
  z-index: -1;
  box-shadow: 0 4px 4px -1px rgba(0, 0, 0, 0.19);
  width: 100%;
  -webkit-transition: all 200ms ease-in-out;
  transition: all 200ms ease-in-out;
}
.video-container .background-menu ul {
  width: 100%;
  list-style: none;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-right: 10px;
}
.video-container .background-menu ul li {
  margin: 0;
  color: white;
  font-size: 12px;
  width: 64px;
  text-align: center;
  font-weight: lighter;
  cursor: pointer;
}
.video-container .background-menu i {
  display: block;
  margin-bottom: 10px;
}
.video-container main {
  padding: 14px;
}
.video-container main .content {
  max-width: 660px;
  margin: 0 auto 0 auto;
}
.video-container main .content .iframe-video-wrapper {
  padding: 56.25% 0 0 0;
  position: relative;
  margin: 13px 0 20px;
}
.video-container main .content .iframe-video-wrapper iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: opacity 400ms;
}
.video-container main .content .iframe-video-wrapper iframe.load {
  opacity: 1;
}
.video-container main .content button {
  display: block;
  margin: auto;
}
.video-container main h1 {
  font-size: 24px;
  line-height: 29px;
}
.video-container main h2 {
  font-size: 18px;
  line-height: 22px;
}
.video-container main h3 {
  font-size: 16px;
  line-height: 22px;
}
.video-container main p {
  font-size: 16px;
  line-height: 22px;
  margin: 20px 0;
}
.video-container main strong,
.video-container main em {
  font-size: inherit;
}
.video-container main blockquote {
  font-style: italic;
  font-size: 18px;
  line-height: 24px;
  padding-left: 15px;
  border-left: #B2E1D6 solid 5px;
  margin-left: 0;
}
.video-container main .caption {
  font-size: 12px;
  line-height: 15px;
  color: #aaacb2;
}
.video-container.fade-enter {
  transform: translateX(100%);
  transition: transform 600ms;
}
.video-container.fade-enter-active {
  transform: none;
}
.video-container.fade-exit {
  transform: none;
  transition: transform 600ms;
}
.video-container.fade-exit-active {
  transform: translateX(100%);
}

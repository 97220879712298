/* Color Pallete */
/* Color Role Definitions */
/* Font Size Definitions */
/*0.9rem;*/
/* Conversation Item */
/* Conversation List */
.question {
  position: relative;
  padding: 14px 16px;
  box-sizing: border-box;
  height: 305px;
  background-color: #ffffff;
  margin: 0 auto 3px auto;
  max-width: 700px;
  display: flex;
  flex-direction: column;
}
@media screen and (max-width: 767px) {
  .question {
    height: 325px;
  }
}
@media screen and (max-width: 375px) {
  .question {
    height: 300px;
  }
}
.question--first-time {
  height: 305px;
}
@media screen and (max-width: 767px) {
  .question--first-time {
    height: 345px;
  }
}
@media screen and (max-width: 375px) {
  .question--first-time {
    height: 315px;
  }
}
@media screen and (max-width: 365px) {
  .question--first-time {
    height: 318px;
  }
}
.question .fixed-send {
  display: none;
}
.question .bigButtonBar button.short-cut {
  border-radius: 21px;
  border: 0px;
  height: 39px;
  vertical-align: center;
  margin: 23px 10px 9px 0;
  white-space: nowrap;
  width: auto;
  padding: 0 18px;
  font-size: 16px;
  color: #ccced3;
  background-color: #f0f1f3;
}
.question .bigButtonBar button.short-cut:hover {
  color: #494d5b;
}
.question .bigButtonBar button.short-cut:last-child {
  margin-right: 0;
}
.question.open .questionInput {
  opacity: 1;
  margin-top: 14px;
}
.question.open .questionInput:disabled {
  background-color: rgba(0, 0, 0, 0);
}
.question.open .welcome .logo img {
  opacity: 1;
  transform: scale(1);
}
.question.open .welcome .text {
  opacity: 1;
}
.question .questionInput {
  width: 100%;
  resize: none;
  height: 119px;
  caret-color: #009B77;
  margin-top: 24px;
}
@media screen and (max-width: 375px) {
  .question .questionInput {
    height: 91px;
  }
}
.question .bigButtonBarVisible {
  opacity: 1;
  visibility: visible;
  transition: visibility 0s 0s, opacity 200ms ease-in-out;
}
.question .suggestions.move + .bigButtonBar {
  transition-delay: 200ms;
}
@media screen and (max-width: 1035px) {
  .question .bigButtonBar button.short-cut {
    margin-top: 20px;
    margin-bottom: 3px;
  }
}
@media screen and (max-width: 767px) {
  .question {
    padding-bottom: 4px;
  }
  .question .bigButtonBar button.short-cut {
    padding: 0 12px;
    height: 27px;
    margin-top: 10px;
    margin-bottom: 0px;
    font-size: 13px;
  }
  .question .bigButtonBar button.short-cut div {
    font-size: 13px;
  }
}
.question-mobile-view-transition {
  transition: height 400ms, margin-top 400ms, position 400ms, top 400ms !important;
}
.question-mobile-view-hide-send-button .send {
  display: none;
}
.question-mobile-view-transition-header {
  transition: min-height 400ms, height 400ms !important;
}
.question-mobile-view {
  margin: 0;
  padding-top: 0px !important;
  background-color: #ffffff !important;
}
.question-mobile-view .questionInput {
  height: 110px;
  margin-top: 0px !important;
}
.question-mobile-view .content-editable__input {
  padding-top: 10px;
}
.question-mobile-view-relative {
  top: 0 !important;
  margin: 0 !important;
  position: relative;
}
.question-mobile-view-fixed {
  top: 0 !important;
  margin: 0 !important;
  position: fixed;
  box-shadow: rgba(0, 0, 0, 0.19) 0px 4px 4px -1px;
  z-index: 1;
}
.questionFixed .question-mobile-view .content-editable__input {
  padding-top: 10px;
}
.welcome {
  margin-left: 6px;
}
.welcome .text {
  color: #494d5b;
  border-radius: 10px;
  background: #f0f1f3;
  display: inline-block;
  font-size: 16px;
  line-height: 20px;
  padding: 6px 9px 5px 10px;
  vertical-align: bottom;
  transition: opacity 200ms 800ms ease-in-out;
  opacity: 0;
}
.welcome .text:last-child {
  margin-top: 5px;
}
@media screen and (max-width: 767px) {
  .welcome .logo {
    display: block;
  }
}
@media screen and (min-width: 768px) {
  .welcome {
    position: relative;
  }
  .welcome .logo {
    position: absolute;
    top: 3px;
    left: -35px;
  }
}
.welcome .logo {
  margin: 0 10px 0 2px;
}
.welcome .logo img {
  transition: all 400ms 400ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
  opacity: 0;
  transform: scale(0);
}
.questionFixed .welcome {
  position: fixed;
  top: 10px;
}
.questionFixed .question .questionInput {
  max-height: 110px;
  max-width: 668px;
}
.questionFixed .question .fixed-send {
  position: absolute;
  bottom: 0;
  right: calc(50% - 348px);
  color: #009B77;
  cursor: pointer;
}
.questionFixed .question .fixed-send.visible {
  display: block;
}
@media screen and (max-width: 700px) {
  .questionFixed .question .fixed-send {
    right: 2px;
  }
}
.questionFixed:not(.fixed) .question {
  background-color: rgba(0, 0, 0, 0);
}
.questionFixed .questionInput {
  position: fixed;
  top: 40px;
  width: 90%;
}
@media screen and (max-width: 767px) {
  .questionFixed .questionInput {
    top: 69px;
    width: calc(100% - 32px);
  }
}
.questionFixed .bigButtonBar {
  position: absolute;
  bottom: 19px;
  left: 16px;
  right: 16px;
}
.question-panel {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background: white;
  z-index: 3;
}
.xircles-status {
  border-radius: 10px;
  color: #ffffff;
  font-size: 12px;
  padding: 3px 8px;
  max-width: 100px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  position: relative;
  top: -3px;
}
.xircles-status.open {
  background: #FFC33B;
}
.xircles-status.solved {
  background: #FFC33B;
}
.xircles-status.in-progress {
  background: #FFC33B;
}
.xircles-status.answer-delivered {
  background: #FFC33B;
}
.xircles-status.chat {
  background: #009B77;
}
.xircles-status.closed {
  background: #009B77;
}
.xircles-status.deleted {
  background: #009B77;
}
.questionPage {
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-positive: 1;
  flex-grow: 1;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: stretch;
  align-items: stretch;
  position: relative;
}
.questionPage.fade-exit-active .scrollPane > div,
.questionPage.fade-exit-active .scrollPane footer,
.questionPage.fade-exit-active .scrollPane layer-conversation-item:not(.layer-selected-item) {
  transition: opacity 400ms;
  opacity: 0;
}
.questionPage.fade-exit-active .panel-header,
.questionPage.fade-exit-active .menu-panel,
.questionPage.fade-exit-active .fixed-send {
  transition: opacity 200ms;
  opacity: 0;
}
.questionPage.fade-exit-active .question {
  transition: height 400ms 200ms ease-out, opacity 200ms ease-out;
  height: 0;
  opacity: 0;
}
.questionPage.fade-exit-active .panel-header {
  transition: height 400ms 200ms ease-out;
  height: 80px;
}
.questionPage.fade-exit-active .fixed .question {
  transition: height 400ms 200ms ease-out;
  opacity: 1;
  height: 260px;
}
.questionPage.fade-exit-active .fixed .welcome,
.questionPage.fade-exit-active .fixed .questionInput {
  transition: opacity 400ms;
  opacity: 0;
}
.questionPage.fade-exit-active .layer-selected-item {
  z-index: 100;
  transition: opacity 30ms 200ms ease-out, top 400ms 200ms ease-out, left 400ms 200ms ease-out, width 400ms 200ms ease-out, height 400ms 200ms ease-out;
  width: 100vw;
  height: 100vh;
}
.questionPage.fade-exit-active .layer-selected-item layer-replaceable-content,
.questionPage.fade-exit-active .layer-selected-item .xircles-handle,
.questionPage.fade-exit-active .layer-selected-item .layer-conversation-title-row,
.questionPage.fade-exit-active .layer-selected-item layer-conversation-last-message {
  opacity: 0;
}
.questionPage layer-conversation-item-date {
  display: none;
}
.questionPage .layer-conversation-list--disabled {
  pointer-events: none;
}
.questionPage .xircles-confirm {
  width: 0px;
  height: 0px;
  border-radius: 15.5px;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.5);
  background-color: #ffffff;
  z-index: 2;
  position: absolute;
  top: -15px;
  right: 25%;
  opacity: 0;
  display: flex;
  overflow: hidden;
}
.questionPage .xircles-confirm.open {
  align-items: center;
  animation: appear 0.2s ease-in forwards;
}
.questionPage .xircles-confirm.close {
  align-items: center;
  animation: disappear 0.2s ease-out forwards;
}
.questionPage .xircles-confirm .xircles-confirm-text {
  display: inline-block;
  margin: 0 15px 0 11px;
}
.questionPage .xircles-confirm .xircles-confirm-action {
  width: 24px;
  height: 24px;
  margin: 3px 5px 4px;
  display: inline-block;
  cursor: pointer;
}
.questionPage .xircles-confirm .xircles-confirm-action.confirm-yes {
  color: #009B77;
}
.questionPage .xircles-confirm .xircles-confirm-action.confirm-no {
  color: #ccced3;
}
.questionPage .xircles-actions {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: 12px;
  background: #494d5b;
}
.questionPage .xircles-actions i {
  display: block;
  margin-bottom: 10px;
}
.questionPage .xircles-actions li {
  margin: 0;
  color: white;
  font-size: 12px;
  width: 64px;
  text-align: center;
  font-weight: lighter;
  cursor: pointer;
}
.questionPage .xircles-actions ul {
  list-style: none;
  display: flex;
  float: right;
  margin: 40px 12px 40px 10px;
}
.questionPage layer-conversation-item layer-avatar,
.questionPage layer-conversation-item .layer-conversation-title-row,
.questionPage layer-conversation-item .xircles-handle {
  transition: opacity 30ms 200ms ease-out;
  opacity: 1;
}
.questionPage layer-conversation-list {
  background-color: #f0f1f3;
  margin: 0 auto 0 auto;
  padding-top: 10px;
  overflow: hidden;
}
@media screen and (min-width: 1035px) {
  .questionPage layer-conversation-list {
    max-width: 1035px;
  }
}
@media screen and (min-width: 768px) and (max-width: 1034px) {
  .questionPage layer-conversation-list {
    max-width: 690px;
  }
}
@media screen and (max-width: 767px) {
  .questionPage layer-conversation-list {
    max-width: 335px;
  }
}
.questionPage .layer-conversation-item-wrapper {
  overflow: hidden;
  height: 100%;
}
.questionPage .layer-conversation-item-wrapper .xircles-footer {
  display: none;
}
.questionPage .layer-conversation-item-wrapper__operator .layer-list-item {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
}
.questionPage .layer-conversation-item-wrapper__operator .layer-list-item .xircles-handle {
  display: none;
}
.questionPage .layer-conversation-item-wrapper__operator .layer-list-item .layer-conversation-item-content {
  box-sizing: border-box;
  width: available;
  height: 110px;
  padding-right: 0;
  padding-bottom: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.questionPage .layer-conversation-item-wrapper__operator .layer-list-item .layer-conversation-item-content layer-conversation-last-message {
  max-width: 270px;
}
.questionPage .layer-conversation-item-wrapper__operator .layer-list-item .layer-conversation-item-content .layer-conversation-title-row {
  position: relative;
  height: 20px;
}
.questionPage .layer-conversation-item-wrapper__operator .layer-list-item .layer-conversation-item-content layer-conversation-title {
  color: #FFC33B;
  width: min-content;
  max-width: min-content;
}
.questionPage .layer-conversation-item-wrapper__operator .layer-list-item .xircles-footer {
  display: block;
  box-sizing: border-box;
  width: 100%;
  height: 53px;
  align-self: flex-end;
  position: relative;
  padding-top: 10px;
  font-size: 12px;
  color: #aaacb2;
}
.questionPage .layer-conversation-item-wrapper__operator .layer-list-item .xircles-footer::before {
  content: '';
  position: absolute;
  top: 0;
  right: -20px;
  left: -10px;
  border: 1px solid #d8d8d8;
}
.questionPage .layer-conversation-item-wrapper__operator .layer-list-item .xircles-footer .operator-status {
  display: flex;
  justify-content: flex-start;
  margin-top: 10px;
  color: white;
}
.questionPage .layer-conversation-item-wrapper__operator .layer-list-item .xircles-footer .operator-status__first {
  margin-right: 10px;
}
.questionPage .layer-conversation-item-wrapper__operator .layer-list-item .xircles-footer .operator-status__first,
.questionPage .layer-conversation-item-wrapper__operator .layer-list-item .xircles-footer .operator-status__second {
  padding: 3px 10px;
  background: #aaacb2;
  border-radius: 12px;
}
.questionPage layer-conversation-item {
  margin: 10px 5px 0;
  position: relative;
  display: inline-block;
  border-bottom: none;
  transition: opacity 30ms ease-out, width 200ms 30ms ease-out, height 200ms 30ms ease-out, margin-top 200ms 30ms ease-out;
  width: 335px;
  height: 126px;
  opacity: 1;
}
.questionPage layer-conversation-item.opening {
  width: 335px;
}
.questionPage layer-conversation-item.layer-item-filtered {
  display: none;
}
@media screen and (max-width: 767px) {
  .questionPage layer-conversation-item {
    margin-left: 0;
    margin-right: 0;
  }
}
.questionPage layer-conversation-item layer-avatar {
  margin-right: 11px;
}
.questionPage layer-conversation-item .xircles-handle {
  position: absolute;
  top: 0;
  right: 0;
  width: 44px;
  height: 100%;
  cursor: pointer;
  z-index: 1;
}
.questionPage layer-conversation-item .xircles-handle div {
  position: absolute;
  top: 50%;
  right: 5px;
  width: 5px;
  height: 40px;
  border-radius: 2.5px;
  background: #f0f1f3;
  transform: translateY(-50%);
}
.questionPage layer-conversation-item .layer-list-item .layer-conversation-left-side .layer-replaceable-inner {
  transition: width 230ms 30ms ease-in-out;
  width: auto;
}
.questionPage layer-conversation-item.open .layer-list-item {
  transform: translateX(-212px);
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.2);
}
.questionPage layer-conversation-item.layer-size-large .layer-list-item {
  align-items: flex-start;
  height: 100%;
  padding: 10px 20px 10px 10px;
  border-radius: 10px;
  background: white;
  z-index: 1;
  transition: transform 200ms ease-in-out, box-shadow 200ms ease-in-out, border-radius 230ms 30ms ease-in-out;
}
.questionPage layer-conversation-item.layer-size-large .layer-list-item layer-conversation-last-message {
  display: block;
  white-space: normal;
  color: #494d5b;
  font-weight: bold;
  line-height: 1.17;
  margin-top: 0;
  transition: all 200ms 30ms ease-out;
  height: 63px;
  font-size: 18px;
  padding-top: 0;
  padding-left: 0;
}
.questionPage layer-conversation-item.layer-size-large .layer-list-item layer-conversation-title {
  font-size: 12px;
  max-width: 140px;
}
.questionPage layer-conversation-item.layer-size-large .layer-conversation-title-row {
  position: absolute;
  bottom: 0;
}
.questionPage layer-conversation-item .layer-conversation-title-row layer-conversation-title {
  width: auto;
  color: #009B77;
  font-weight: bold;
}
.questionPage .right-panel {
  display: none;
}
.questionPage .left-panel {
  -ms-flex: 1 1;
  flex: 1 1;
  max-width: none;
  overflow: auto;
  background-color: white;
}
.questionPage .question {
  width: 100%;
  z-index: 1;
  xtransition: top 30ms 200ms ease-out;
  xtop: 0;
}
.questionPage .open layer-conversation-item layer-avatar,
.questionPage .open layer-conversation-item .layer-conversation-title-row,
.questionPage .open layer-conversation-item .xircles-handle {
  transition: opacity 30ms ease-out;
  opacity: 0;
}
.questionPage .open layer-conversation-item .layer-list-item {
  border-radius: 0;
}
.questionPage .open layer-conversation-item .layer-list-item .layer-conversation-left-side .layer-replaceable-inner {
  width: 0px;
}
.questionPage .open layer-conversation-item.layer-size-large .layer-list-item layer-conversation-last-message {
  transition: all 200ms ease-out;
  width: 100%;
  font-size: 24px;
  padding-top: 43px;
  padding-left: 10px;
}
.questionPage .open layer-conversation-item.layer-selected-item {
  transition: opacity 30ms 200ms ease-out, width 200ms ease-out, height 200ms ease-out, margin-top 30ms ease-out;
  width: 100%;
  height: 100%;
  margin-top: 0;
}
.questionPage .open layer-conversation-item:not(.layer-selected-item) {
  opacity: 0;
}
.questionPage.fade-exit .question {
  xtransition: top 30ms ease-out;
  xtop: -100px;
}
.questionPage .fixed .question {
  position: fixed;
  top: -126px;
  transition: none;
  max-width: none;
  box-shadow: rgba(0, 0, 0, 0.19) 0px 4px 4px -1px;
}
.questionPage .fixed .question .bigButtonBar {
  display: none;
}
.questionPage .fixed .question .welcome {
  left: 50%;
  max-width: 700px;
  margin-left: -328px;
}
@media screen and (max-width: 700px) {
  .questionPage .fixed .question .welcome {
    margin-left: 0;
    left: 22px;
  }
}
.questionPage .fixed .question .questionInput {
  max-width: 668px;
  left: 50%;
  margin-left: -334px;
}
@media screen and (max-width: 700px) {
  .questionPage .fixed .question .questionInput {
    margin-left: 0;
    left: 16px;
  }
}
.questionPage .fixed .question .suggestions {
  max-width: 668px;
  left: 50%;
  margin-left: -334px;
}
.questionPage .fixed .scrollPane {
  padding-top: 305px;
}
@media screen and (max-width: 767px) {
  .questionPage .fixed .scrollPane {
    padding-top: 325px;
  }
}
@media screen and (max-width: 375px) {
  .questionPage .fixed .scrollPane {
    padding-top: 300px;
  }
}
.questionPage .fixed .scrollPane--first-time {
  padding-top: 305px;
}
@media screen and (max-width: 767px) {
  .questionPage .fixed .scrollPane--first-time {
    padding-top: 345px;
  }
}
@media screen and (max-width: 365px) {
  .questionPage .fixed .scrollPane--first-time {
    padding-top: 318px;
  }
}
.questionPage .scrollPane {
  z-index: 0;
  background-color: #f0f1f3;
  min-height: calc(100vh - 254px);
}
.menu-button {
  position: fixed;
  right: 10px;
  top: 10px;
}
@media screen and (max-width: 767px) {
  .menu-button {
    top: 0;
    right: 0;
  }
}
.menu-button button {
  padding: 10px;
  color: #666666;
  font-weight: bold;
  cursor: pointer;
  border-width: 0;
}
@media only screen and (min-width: 1035px) {
  .menu-button button {
    width: 80px;
    text-align: right;
  }
}
.xircles-handle:hover div {
  animation: handle 701ms ease-in-out;
}
layer-presence {
  display: none !important;
}
layer-identity-item.layer-size-large .layer-list-item {
  padding-top: 10px;
  padding-bottom: 10px;
  min-height: auto;
}
layer-conversation-item.removed-item {
  animation: removed-item-animation 0.2s cubic-bezier(0.55, -0.04, 0.91, 0.94) forwards;
}
layer-conversation-item .layer-list-item .layer-conversation-item-content {
  position: relative;
  height: 100%;
  padding-right: 36px;
}
#root > div {
  width: 100%;
}
@keyframes handle {
  0%,
  100% {
    right: 20px;
  }
  28%,
  71% {
    right: 25px;
  }
}
@keyframes removed-item-animation {
  0% {
    opacity: 1;
    transform: scale(1);
  }
  99% {
    opacity: 0;
    transform: scale(0);
    width: 335px;
    height: 126px;
    margin: 10px 5px 0;
  }
  100% {
    opacity: 0;
    transform: scale(0);
    height: 0;
    width: 0;
    margin: 0;
  }
}
@keyframes appear {
  0% {
    width: 191px;
    height: 31px;
    opacity: 0.6;
    transform: scale(0.6);
  }
  100% {
    opacity: 1;
    transform: scale(1);
    width: 191px;
    height: 31px;
  }
}
@keyframes disappear {
  0% {
    opacity: 1;
    transform: scale(1);
    width: 191px;
    height: 31px;
  }
  99% {
    opacity: 0.6;
    transform: scale(0.6);
  }
  100% {
    opacity: 0.6;
    transform: scale(0);
    width: 191px;
    height: 31px;
  }
}

/* Color Pallete */
/* Color Role Definitions */
/* Font Size Definitions */
/*0.9rem;*/
.pull-down {
  width: 100%;
  background-color: #ffffff;
  border-radius: 5px;
  box-shadow: 0 2px 14px 0 rgba(0, 0, 0, 0.21);
  padding: 10px;
  position: absolute;
  z-index: 100;
  margin: 8px -10px 0;
}
.pull-down__overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.pull-down__title {
  font-family: "Roboto", sans-serif;
  font-size: 18px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.17;
  letter-spacing: normal;
  color: #009B77;
}
.pull-down__button {
  color: #ccced3;
  position: absolute;
  top: 0;
  right: 0;
}
.pull-down__suggestions {
  margin: 20px auto 0;
}
